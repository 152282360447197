import { ReactNode } from 'react';
import Header from './Header';

function AppLayout({ children }: { children: ReactNode }) {
  return (
    <div className="project_pool_page w-full">
      <div className="page_main_max_width m-auto">
        <Header />
        {children}
      </div>
    </div>
  );
}
export default AppLayout;
