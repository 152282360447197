/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { providers, Signer } from 'ethers';
import { getCachedAccount, getDefaultChainId } from '../Utils/AppUtils';

type Provider = providers.Provider;
export type AuthReducer = {
  chainId: number;
  account: string;
  readOnlyProvider: Provider;
  readWriteProvider: Signer;
  isUnsupportedChain: boolean;
  isChainSwitcher: boolean;
  requestedChainId: number;
};

const initialState: AuthReducer = {
  chainId: getDefaultChainId(),
  account: getCachedAccount(),
  readOnlyProvider: {} as Provider,
  readWriteProvider: {} as Signer,
  isUnsupportedChain: false,
  isChainSwitcher: false,
  requestedChainId: 0,
};
export const common = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setChainId: (state, action: PayloadAction<number>) => {
      state.chainId = action.payload;
    },
    setRequestedChainId: (state, action: PayloadAction<number>) => {
      state.requestedChainId = action.payload;
    },
    setAccount: (state, action: PayloadAction<string>) => {
      state.account = action.payload;
    },
    setReadOnlyProvider: (state, action: PayloadAction<Provider>) => {
      state.readOnlyProvider = action.payload;
    },
    setReadWriteProvider: (state, action: PayloadAction<Signer>) => {
      state.readWriteProvider = action.payload;
    },
    setIsUnsupportedChainId: (state, action: PayloadAction<boolean>) => {
      state.isUnsupportedChain = action.payload;
    },
    setIsChainSwitcher: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isChainSwitcher = action.payload;
    },
  },
});

export const {
  setAccount,
  setChainId,
  setReadOnlyProvider,
  setReadWriteProvider,
  setIsUnsupportedChainId,
  setIsChainSwitcher,
  setRequestedChainId,
} = common.actions;

export default common.reducer;
