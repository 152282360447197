import Axios from 'axios';
import { BigNumber } from 'ethers';
import { Contract, Provider } from 'ethers-multicall';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import erc721Abi from '../../../Artifacts/Erc/ERC721.json';
import { appVersion } from '../../../Config/AppConfig';
import { CONTRACT_HISTORY } from '../../../Constants';
import { AppDispatch, RootState } from '../../../Store';

import { Actions } from '../../../Enums';
import { useAppSelector } from '../../../Hooks/state';
import { setIsUnsupportedChainId } from '../../../Store/AuthReducer';
import { setIsLoading, setIsRefreshData } from '../../../Store/CommonReducer';
import {
  initializeContract,
  initializeERC1155TokenContract,
  initializeReadOnlyProvider,
} from '../../../Utils/ContractUtils';
import { Pool } from '../../Pools/Types/PoolRowType';
import { getProject } from '../../Pools/Utils';
import { setNfts, setPoolInfo } from '../Store/Reducer';
import {
  cacheAllNfts,
  cacheNfts,
  getAllCachedNfts,
  getCachedNfts,
} from '../Utils';
import useStakerDetails from './useStakerDetails';

export default function useInitPoolInfo() {
  const { action, isRefreshData } = useAppSelector(
    (state: RootState) => state.common,
  );
  const { poolInfo, nfts } = useAppSelector(
    (state: RootState) => state.poolInfo,
  );
  const { account, chainId: selectedChainId } = useAppSelector(
    (state: RootState) => state.auth,
  );

  const isFetchAllNfts = !window.location.pathname.match('/pool');
  const [readOnlyContract, setReadOnlyContract] = useState<any>();

  const { chainId, projectId } = useParams();
  const projectChainId = +(chainId || 0);
  const dispatch = useDispatch<AppDispatch>();
  const provider = initializeReadOnlyProvider(projectChainId);
  const {
    address,
    nftDetails: { baseUrl },
    type,
  } = poolInfo;
  const { getStakerDetails } = useStakerDetails();
  useEffect(() => {
    dispatch(setIsLoading(true));
    const localPoolInfo: Pool =
      getProject(projectChainId, +(projectId || 0)) || {};
    dispatch(setPoolInfo({ ...poolInfo, ...localPoolInfo }));
  }, []);

  const getNftDetails = async (ids: BigNumber[], rewardToken: string) => {
    if (ids.length > 0) {
      let nftIds = ids;
      if (!isFetchAllNfts) {
        nftIds = nftIds.slice(0, 4);
      } else if (nftIds.length > 50) {
        nftIds = nftIds.slice(0, 50);
      }
      let nftBalances = [];
      if (type === 1) {
        const erc1155TokenContract = initializeERC1155TokenContract({
          tokenAddress: rewardToken,
          provider,
        });
        nftBalances = await erc1155TokenContract.balanceOfBatch(
          new Array(nftIds.length).fill(address),
          nftIds,
        );
      } else {
        const ethcallProvider = new Provider(provider);
        const contract = new Contract(rewardToken, erc721Abi.abi);
        await ethcallProvider.init();
        const contractCall = nftIds.map((id: BigNumber) =>
          contract.ownerOf(id.toNumber()),
        );
        nftBalances = await ethcallProvider.all(contractCall);
      }

      const nftIdsWithBalance: { id: number; balance: number }[] = [];
      const apis: any = [];
      nftBalances.forEach((value: any, index: number) => {
        const condition =
          type === 1
            ? value.gt(0)
            : value.toLocaleLowerCase() === address.toLocaleLowerCase();
        if (condition) {
          const id = nftIds[index]?.toNumber() || 0;
          nftIdsWithBalance.push({
            id,
            balance: type === 1 ? value.toNumber() : 1,
          });
          apis.push(
            Axios({
              method: 'get',
              url: `${baseUrl}${id}`,
            }),
          );
        }
      });
      const response: any = await Axios.all(apis);
      const data = nftIdsWithBalance.map(({ id, balance }, index: number) => ({
        id,
        image: response[index].data?.image,
        balance,
      }));
      dispatch(setNfts({ loading: false, data }));
      if (isFetchAllNfts) {
        cacheAllNfts({ loading: false, data }, projectId || '');
      } else {
        cacheNfts({ loading: false, data }, projectId || '');
      }
    } else if (nfts.loading && nfts.data.length > 0) {
      dispatch(setNfts({ loading: false, data: [] }));
    }
  };

  const getProjectDetails = async () => {
    try {
      const abiPath = CONTRACT_HISTORY[appVersion][`type${type}`];
      const contract = initializeContract({
        contract: address,
        abiPath,
        provider,
      });
      setReadOnlyContract(contract);
      const {
        batchSize: maxBatchSize,
        minimumStakeAmount: minStakeAmount,
        emissionRate: rewardPerSec,
        rewardToken,
        stakingToken: stakeToken,
        stakingStartTime: startTime,
        unbonding: unbondingPeriod,
        availableRewards,
      } = await contract.getDetails();
      const {
        totalAmountStaked: totalFundsStaked,
        totalAmountUnstaked: totalFundsUnstaked,
        totalRewardDistributed: totalNftDistributed,
      } = await contract.getTVLDetails();
      const nftIds = await contract.getAllRewardNftIds();
      const totalRewards = availableRewards.add(totalNftDistributed);
      dispatch(
        setPoolInfo({
          ...poolInfo,
          projectId: projectId || '',
          maxBatchSize,
          minStakeAmount,
          rewardPerSec,
          rewardToken,
          stakeToken,
          startTime,
          totalRewards,
          totalFundsStaked,
          totalFundsUnstaked,
          totalNftDistributed,
          unbondingPeriod,
        }),
      );
      dispatch(setIsLoading(false));
      getNftDetails(nftIds, rewardToken);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (address) {
      getProjectDetails();
    }
  }, [address]);

  useEffect(() => {
    const cachedNfts = isFetchAllNfts
      ? getAllCachedNfts(projectId || '')
      : getCachedNfts(projectId || '');
    if (cachedNfts) {
      dispatch(setNfts(cachedNfts));
    }
  }, []);

  useEffect(() => {
    if (readOnlyContract && account) {
      getStakerDetails(true);
    }
  }, [readOnlyContract, account]);

  useEffect(() => {
    if (selectedChainId > 0) {
      dispatch(setIsUnsupportedChainId(projectChainId !== selectedChainId));
    } else {
      dispatch(setIsUnsupportedChainId(true));
    }
  }, [selectedChainId]);

  useEffect(() => {
    if (isRefreshData) {
      if (
        action === Actions.stake ||
        action === Actions.unstake ||
        action === Actions.cancelWithdraw ||
        action === Actions.withdraw
      ) {
        getStakerDetails();
        getProjectDetails();
      }
      if (action === Actions.claim) {
        getStakerDetails();
      }
      dispatch(setIsRefreshData(false));
    }
  }, [isRefreshData]);
  return {
    getStakerDetails,
  };
}
