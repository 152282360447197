/* eslint-disable @typescript-eslint/indent */
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../../Assets/Css/Pool.css';
import close from '../../Assets/Images/close.svg';
import hamburgerMenu from '../../Assets/Images/hamburger-menu.svg';
import headerLogo from '../../Assets/Images/zelato.svg';
import { useAppSelector } from '../../Hooks/state';
import useAuth from '../../Hooks/useAuth';
import { RootState } from '../../Store';
import SwitchToProjectChain from '../ChainSelection/SwitchToProjectChain';
import WalletInfo from '../ChainSelection/WalletInfo';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const {
    account,
    isUnsupportedChain,
    // chainId: currentChainId,
  } = useAppSelector((state: RootState) => state.auth);
  const { handleNetwork } = useAuth();
  const { chainId } = useParams();
  const projectChainId = +(chainId || 0);
  const isConnected = !!account;
  //   const walletConnector = localStorage.getItem('walletConnector');

  return (
    <div>
      <div className="w-full relative hidden font_chakra p-5 md:flex justify-between items-center">
        <div className="flex text_primary font-medium items-center header_text">
          <Link to="/">
            <img
              src={headerLogo}
              alt="zelato-logo"
              className="h-5 header_logo object-contain"
            />
          </Link>
          {/* {HEADER_LINKS.map((link) => (
            <Link to={link.url} key={link.name} className="ml-7">
              {link.name}
            </Link>
          ))} */}
        </div>
        <div className="flex items-center gap-x-3">
          {isConnected && (
            <div className="flex items-center justify-center gap-x-1.5">
              {isUnsupportedChain && (
                <SwitchToProjectChain projectChainId={projectChainId} />
              )}
              <WalletInfo />
            </div>
          )}
          {!isConnected && (
            <button
              type="button"
              className="rounded-xl connect_wallet_style h-10 font_chakra font-medium text_black"
              onClick={() => handleNetwork()}
            >
              <p>CONNECT WALLET</p>
            </button>
          )}
        </div>
      </div>
      <div className="flex md:hidden justify-between items-center py-5 px-3 bg-black">
        {!isOpen && (
          <>
            <Link to="/">
              <img
                src={headerLogo}
                alt="zelato-logo"
                className="h-5 header_logo object-contain"
              />
            </Link>
            <div className="flex justify-center items-center">
              <button type="button" onClick={() => setIsOpen(!isOpen)}>
                <img
                  src={hamburgerMenu}
                  alt="menu"
                  className="hamburger_menu"
                />
              </button>
            </div>
          </>
        )}

        {isOpen && (
          <div className="w-full flex justify-end">
            <button
              type="button"
              onClick={() => setIsOpen(!isOpen)}
              className="h-6 w-5"
            >
              <img
                src={close}
                alt="close"
                className="object-contain w-full h-full"
              />
            </button>
          </div>
        )}

        {isOpen && (
          <div className="header_sm_bg absolute flex flex-col z-10 justify-between items-center top-16 right-0 header_modal gap-y-4">
            <Link to="/" className="header_modal_links activeLink">
              HOME
            </Link>
            {/* <Link to="/" className="header_modal_links">
              PROJECTS
            </Link>
            <Link to="/" className="header_modal_links">
              HOW IT WORKS
            </Link> */}
            {isConnected && (
              <div className="flex items-center flex-col gap-y-4 justify-center gap-x-1.5">
                {isUnsupportedChain && (
                  <SwitchToProjectChain projectChainId={projectChainId} />
                )}
                <WalletInfo />
              </div>
            )}
            {!isConnected && (
              <button
                type="button"
                className="rounded-xl connect_wallet_style h-10 font_chakra font-medium text_black"
                onClick={() => handleNetwork()}
              >
                <p>CONNECT</p>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
export default Header;
