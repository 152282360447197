import binanceIcon from '../Assets/Icons/binance.svg';
import ethIcon from '../Assets/Icons/ethereum.svg';
import polygonIcon from '../Assets/Icons/polygon.svg';

interface ChainInfoInterface {
  [key: number]: {
    [key: string]: string | number;
  };
}
export type ChainDataType = {
  [key: number]: {
    chainId: string;
    chainName: string;
    nativeCurrency: {
      name: string;
      symbol: string;
      decimals: number;
    };
    rpcUrls: string[];
    blockExplorerUrls: string[];
  };
};
export const CHAIN_INFO: ChainInfoInterface = {
  56: {
    symbol: 'BNB',
    chain: 'bsc',
    decimals: 18,
    name: 'Binance',
    icon: binanceIcon,
    hexChainId: '0x38',
  },
  97: {
    symbol: 'BNB',
    chain: 'bsc',
    decimals: 18,
    name: 'BSC Testnet',
    icon: binanceIcon,
    hexChainId: '0x61',
  },
  4: {
    symbol: 'ETH',
    chain: 'ethereum',
    decimals: 18,
    name: 'Rinkeby',
    icon: ethIcon,
    hexChainId: '0x4',
  },
  1: {
    symbol: 'ETH',
    chain: 'ethereum',
    decimals: 18,
    name: 'Ethereum',
    icon: ethIcon,
    hexChainId: '0x1',
  },
  80001: {
    chain: 'polygon',
    symbol: 'MATIC',
    decimals: 18,
    name: 'Matic-Testnet',
    icon: polygonIcon,
    hexChainId: '0x13881',
  },
  137: {
    chain: 'polygon',
    symbol: 'MATIC',
    decimals: 18,
    name: 'Polygon',
    icon: polygonIcon,
    hexChainId: '0x89',
  },
};
export const alchemyRinkebyApi: string[] = [
  'rinkeby',
  '6Abto5co0LubIKUWva-uq2xpGdUKUc66',
];
export const alchemyMainnetApi: string[] = [
  'homestead',
  'nfrnWAqN2Frs4UJ_hVhF3xiz_eo9fkKd',
];

export const walletConnectBridgeUrl: string =
  'https://bridge.walletconnect.org';

export const JSON_RPC_PROVIDER: { [key: number]: string } = {
  1: 'https://eth-mainnet.alchemyapi.io/v2/1tU4pDdv8o6LdwbQvYLyXDhj-49LQNuo',
  4: 'https://eth-rinkeby.alchemyapi.io/v2/6Abto5co0LubIKUWva-uq2xpGdUKUc66',
  97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  56: 'https://bsc-dataseed.binance.org',
  80001: 'https://polygon-mumbai.g.alchemy.com/v2/xOgHYlzhaxkYwc3BUv_ut5J4JoOsldhN',
  137: 'https://holy-old-sea.matic.discover.quiknode.pro/4d868853d8004aa200fff33b5054a73c60ecefd7/',
};

export const ADD_CHAIN_DATA: ChainDataType = {
  137: {
    chainId: '0x89',
    chainName: 'Matic-Mainnet',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
    blockExplorerUrls: ['https://explorer.matic.network/'],
  },
  56: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/'],
  },
  97: {
    chainId: '0x61',
    chainName: 'Binance Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
    blockExplorerUrls: ['https://testnet.bscscan.com/address/'],
  },
  1: {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://etherscan.io/'],
    blockExplorerUrls: ['https://etherscan.io/block/'],
  },
};
