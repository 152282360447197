export interface DefiContract {
  [key: number]: {
    [key: string]: string;
    [key: number]: string;
  };
}
export const CONTRACT_HISTORY: DefiContract = {
  1: {
    56: '',
    1: '',
    4: 'fb2e9281476069a1c6af70e43c744d0a103e98de',
    80001: '0x60d18f38498AF46FAF7e6e3c920b67D7072fFB00',
    type0: 'v1/ERC721NftStaking.json',
    type1: 'v1/ERC1155NftStaking.json',
  },
};
