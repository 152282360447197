import { calcTimeDelta } from 'react-countdown';
import { useDispatch } from 'react-redux';
import error from '../../Assets/Designs/error.svg';
import rewardWaiting from '../../Assets/Designs/reward-pop-up.svg';
import waiting from '../../Assets/Designs/waiting.svg';
import chumbiLogo from '../../Assets/Images/chumbi-logo.svg';
import { STATUS } from '../../Config/AppConstants';
import { Actions, TrxState } from '../../Enums';
import { useAppSelector } from '../../Hooks/state';
import ClaimReceipt from '../../Routes/PoolInfo/Components/Actions/Claim/ClaimReceipt';
import { AppDispatch, RootState } from '../../Store';
import {
  setAction,
  setIsRefreshData,
  setTrxResponse,
  setTrxState,
} from '../../Store/CommonReducer';
import CommonModal from '../Misc/CommonModal';
import CountdownTimer from '../Misc/CountdownTimer';

function TrxStateModal({ action }: { action: Function }) {
  let heading = '';
  let descriptions = '';
  let img = '';
  let btnName = '';
  const {
    trxResponse,
    action: trxAction,
    trxState,
  } = useAppSelector((state: RootState) => state.common);
  const { poolInfo, stakerInfo } = useAppSelector(
    (state: RootState) => state.poolInfo,
  );
  const { unstakeTime } = stakerInfo;

  const { unbondingPeriod } = poolInfo;
  const { args } =
    trxResponse?.data?.events?.find(
      (item: any) => item?.event === 'NftClaimed',
    ) || {};
  const dispatch = useDispatch<AppDispatch>();

  const isWithdraw = trxAction === Actions.withdraw;
  const isClaim = trxAction === Actions.claim;
  const isUnstake = trxAction === Actions.unstake;
  switch (trxState) {
    case TrxState.error:
      heading = 'OOPS ! THE CREAM SPLIT';
      descriptions =
        'THERE SEEMS TO BE AN ERROR IN YOUR TRANSACTION. PLEASE TRY AGAIN.';
      btnName = 'TRY AGAIN';
      img = error;
      break;
    case TrxState.completed:
      btnName = isWithdraw || isUnstake ? 'STAKE AGAIN' : 'VIEW POOL';
      if (isWithdraw) {
        heading = 'YOUR EXIT IS COMPLETE';
        descriptions =
          'YOU HAVE SUCCESSFULLY WITHDRAWN YOUR TOKENS FROM THE POOL';
      } else if (isUnstake) {
        heading = 'WITHDRAWAL TIMER ON';
        descriptions =
          'YOU HAVE SUCCESSFULLY WITHDRAWN YOUR TOKENS FROM THE POOL';
      } else if (isClaim) {
        heading = 'ALL YOURS !';
        descriptions = `YOUR ${
          args?.amount?.length > 1 ? `${args?.amount?.length} NFTS` : 'NFT'
        } HAVE BEEN SUCCESSFULLY DELIVERED TO YOUR WALLET`;
        btnName = '';
      } else {
        heading = 'CONGRATULATIONS !';
        descriptions = 'YOU HAVE SUCCESSFULLY ENTERED THE CHUMBI VALLEY POOL';
      }
      img = chumbiLogo;
      break;

    case TrxState.pending:
      img = waiting;

      if (isWithdraw) {
        heading = 'WITHDRAWAL IN PROGRESS';
      } else if (isUnstake) {
        heading = 'SAD TO SEE YOU GO !';
      } else if (isClaim) {
        heading = 'PACKING THEM UP !';
        img = rewardWaiting;
      } else {
        heading = 'SCOOPING IN 1..2..3...';
      }
      descriptions = 'WAITING FOR YOUR TRANSACTION TO CONFIRM';
      btnName = '';
      break;

    default:
      heading = 'CONFIRM TRANSACTION !';
      descriptions = 'WAITING FOR YOU TO CONFIRM TRANSACTION';
      btnName = '';
      img = waiting;
      break;
  }

  const disableClose =
    trxState === TrxState.pending || trxState === TrxState.processing;
  let withdrawableTimestamp = 0;
  if (isUnstake && unbondingPeriod.gt(0)) {
    withdrawableTimestamp =
      (unstakeTime.toNumber() + unbondingPeriod.toNumber()) * 1000;
  }
  const isTimerCompleted = calcTimeDelta(withdrawableTimestamp).completed;

  return (
    <CommonModal
      disableClose={disableClose}
      heading={heading}
      description={descriptions}
    >
      {isClaim && trxResponse?.status === STATUS.success ? (
        <ClaimReceipt />
      ) : (
        <div>
          {isUnstake &&
          trxResponse?.status === STATUS.success &&
          !isTimerCompleted ? (
            <CountdownTimer
              onComplete={() => dispatch(setIsRefreshData(true))}
              onStart={() => {}}
              timestamp={withdrawableTimestamp}
              classNames="unstake-countdown-timer"
            />
          ) : (
            <object
              type="image/svg+xml"
              className="h-48"
              aria-labelledby="pool"
              data={img}
            />
          )}
        </div>
      )}

      {btnName && (
        <button
          type="button"
          className="staking_modal_approval_btn"
          onClick={() => {
            if (trxResponse?.status === STATUS.success) {
              if (isWithdraw || isUnstake) {
                dispatch(setAction(Actions.stake));
              } else {
                dispatch(setAction(Actions.unset));
              }
              dispatch(setTrxState(TrxState.unset));
              dispatch(setTrxResponse({ status: '', data: {} }));
            } else {
              action();
            }
          }}
        >
          <p className="staking_modal_approval_btn_text">{btnName}</p>
        </button>
      )}
    </CommonModal>
  );
}
export default TrxStateModal;
