import { BigNumber } from 'ethers';
import { useState } from 'react';
import chumbiLogo from '../../../../../Assets/Images/chumbi-logo.svg';
import Button from '../../../../../Components/Misc/Button';
import CommonModal from '../../../../../Components/Misc/CommonModal';
import TrxStateModal from '../../../../../Components/Modals/TrxStateModal';
import { STATUS } from '../../../../../Config/AppConstants';
import { TrxState } from '../../../../../Enums';
import { useAppDispatch, useAppSelector } from '../../../../../Hooks/state';
import useApprove from '../../../../../Hooks/useApprove';
import useStake from '../../../../../Logic/PoolInfo/Hooks/useStake';
import { RootState } from '../../../../../Store';
import { setAllowance, setTrxState } from '../../../../../Store/CommonReducer';
import { switchNetwork } from '../../../../../Utils/ChainUtils';
import { currencyFormatter } from '../../../../../Utils/GeneralUtils';
import BuyTokens from './BuyTokens';
import Info from './Info';
import Input from './Input';

function StakingModal() {
  const { poolInfo, stakerInfo, stakingBalance } = useAppSelector(
    (state: RootState) => state.poolInfo,
  );
  const { isUnsupportedChain } = useAppSelector(
    (state: RootState) => state.auth,
  );
  const { trxState, allowance } = useAppSelector(
    (state: RootState) => state.common,
  );
  const [inputValue, setInputValue] = useState<number>(0);
  const {
    tokenDetails: { symbol, address, decimals },
    minStakeAmount,
    chainId,
  } = poolInfo;
  const hasAllowance = BigNumber.from(allowance).gt(0);
  const { approve } = useApprove();
  const { stake } = useStake();
  const dispatch = useAppDispatch();
  const minStake = +currencyFormatter(minStakeAmount, decimals) || 1;
  const { staked } = stakerInfo;
  const stakedAmount = +currencyFormatter(staked, decimals);
  const balance = +currencyFormatter(stakingBalance, decimals);
  const hasStakingAllowed =
    inputValue > 0 &&
    stakedAmount + inputValue >= minStake &&
    inputValue <= balance;

  const callAction = () => {
    if (hasAllowance) {
      stake(inputValue);
    } else {
      approve(address).then((res) => {
        if (res.status === STATUS.success) {
          dispatch(setAllowance('10000000000000000000000000000'));
        }
      });
    }
  };

  const handleAction = async () => {
    if (isUnsupportedChain) {
      const res = await switchNetwork(chainId);
      if (res === STATUS.success) {
        callAction();
      } else {
        dispatch(setTrxState(TrxState.error));
      }
    } else {
      callAction();
    }
  };
  return (
    <div>
      {balance > minStake ? (
        <div>
          {trxState !== TrxState.unset ? (
            <TrxStateModal action={handleAction} />
          ) : (
            <CommonModal
              heading={hasAllowance ? 'SET YOUR STAKE' : 'LET’S GET STARTED'}
              description={
                hasAllowance
                  ? 'SET YOUR STAKING AMOUNT & CONFIRM THE TRANSACTION IN YOUR WALLET'
                  : 'APPROVE YOUR WALLET TO ENTER THE CHUMBI VALLEY POOL'
              }
            >
              {hasAllowance ? (
                <div className="mb-9 w-full">
                  <Info symbol={symbol} balance={balance} />
                  <Input
                    value={inputValue?.toString()}
                    max={balance}
                    onChange={(value: number) => setInputValue(value)}
                  />
                  {inputValue > 0 &&
                    (inputValue + stakedAmount < minStake ||
                      inputValue > balance) && (
                      <span className="text-red-600">
                        {inputValue + stakedAmount < minStake
                          ? `Amount should be greater than ${minStake}`
                          : `Amount should be less than or equal to ${balance}`}
                      </span>
                    )}
                </div>
              ) : (
                <div className="staking_modal_approval_img">
                  <img
                    src={chumbiLogo}
                    alt="pool"
                    height="200px"
                    width="200px"
                    className="w-full bg-transparent h-full object-contain"
                  />
                </div>
              )}

              <Button
                className="staking_modal_approval_btn"
                onClick={() => handleAction()}
                loading={trxState !== TrxState.unset}
                disabled={hasAllowance ? !hasStakingAllowed : false}
              >
                <p className="staking_modal_approval_btn_text">
                  {hasAllowance ? 'CONFIRM' : 'APPROVE'}
                </p>
              </Button>
            </CommonModal>
          )}
        </div>
      ) : (
        <BuyTokens />
      )}
    </div>
  );
}
export default StakingModal;
