import { BigNumber } from 'ethers';
import { Pool } from '../../Pools/Types/PoolRowType';

export const NFTS_IN_POOL = 'NFTS IN POOL';
export const TRX_STATE = {
  unset: '',
  processing: 'Processing...',
  approving: 'Approving...',
  pending: 'Pending...',
  completed: 'Completed',
  error: 'Error',
};

export const ACTION = {
  unset: 0,
  stake: 1,
  unstake: 2,
  withdraw: 3,
  claim: 4,
  nftCalculator: 5,
  getDetails: 6,
  cancelWithdraw: 7,
};
export const NFT_WEIGHTAGE = '1000000000000000000';
export const LS_KEY_NFTS = 'nfts';
export const BUY_TOKEN_PLATFORM = ['Gate', 'Kucoin', 'Quickswap', 'MEXC'];

export const POOL_INFO: Pool = {
  totalRewards: 0,
  exchanges: [],
  endTime: '0',
  image: '',
  address: '',
  name: '',
  description: '',
  chainId: 0,
  tokenDetails: {
    symbol: '',
    decimals: 0,
    address: '',
  },
  nftDetails: { baseUrl: '', nftExplorer: '' },
  socials: {},
  nativeToken: false,
  website: '',
  projectId: '0',
  stakeToken: '',
  rewardNftToken: '',
  rewardNftIds: [''],
  rewardPerSec: BigNumber.from('0'),
  maxBatchSize: BigNumber.from('0'),
  minStakeAmount: BigNumber.from('0'),
  rewardToken: '',
  totalFundsUnstaked: BigNumber.from('0'),
  totalNftDistributed: BigNumber.from('0'),
  startTime: '',
  timestamp: '',
  totalFundsStaked: BigNumber.from('0'),
  type: 0,
  unbondingPeriod: BigNumber.from('1'),
};
