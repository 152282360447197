/* eslint-disable @typescript-eslint/no-shadow */
import WalletConnectProvider from '@walletconnect/web3-provider';
import { providers } from 'ethers';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Web3Modal from 'web3modal';
import { JSON_RPC_PROVIDER } from '../Config/ChainConfig';
import {
  setAccount,
  setChainId,
  setReadOnlyProvider,
  setReadWriteProvider,
} from '../Store/AuthReducer';
import { setNativeBalance } from '../Store/CommonReducer';
import { initializeReadOnlyProvider } from '../Utils/ContractUtils';

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: JSON_RPC_PROVIDER,
    },
    chainId: 56,
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
  theme: {
    background: '#21262A',
    main: 'rgb(199, 199, 199)',
    secondary: 'rgb(136, 136, 136)',
    border: 'rgba(255, 255, 255, 0.04)',
    hover: 'rgba(255, 255, 255, 0.1)',
  },
});

function useAuth() {
  const dispatch = useDispatch();

  const [web3ModalProvider, setWeb3ModalProvider] = useState<any>({});
  const { cachedProvider } = web3Modal;

  const getNativeBalance = async (
    ethersProvider: { getBalance: (arg0: any) => any },
    account: any,
  ) => {
    const nativeBalance = await ethersProvider.getBalance(account);
    dispatch(setNativeBalance(nativeBalance));
  };

  const reset = () => {
    setWeb3ModalProvider(undefined);
    dispatch(setAccount(''));
    dispatch(setChainId(0));
    localStorage.removeItem('account');
    localStorage.removeItem('chainId');
  };
  const initialize = async (provider: any) => {
    const ethersProvider = new providers.Web3Provider(provider);
    localStorage.setItem('walletConnector', ethersProvider.connection.url);
    const { chainId } = await ethersProvider.getNetwork();
    const account = await ethersProvider.listAccounts();
    dispatch(setReadOnlyProvider(initializeReadOnlyProvider(chainId)));
    dispatch(setReadWriteProvider(ethersProvider.getSigner(0)));
    dispatch(setAccount(account[0]));
    // dispatch(setAccount('0xBaF79842226e88E909D8B74C179aFF4ffd19bc73'));
    dispatch(setChainId(chainId));
    getNativeBalance(ethersProvider, account[0]);
    // dispatch(setReadOnlyProvider(initializeReadOnlyProvider(chainId)));
    localStorage.setItem('account', account[0]);
    localStorage.setItem('chainId', chainId.toString());
  };

  const subscribeEvents = async (provider: {
    on: (arg0: string, arg1: { (): void; (): void }) => void;
  }) => {
    provider.on('accountsChanged', () => {
      initialize(provider);
    });
    provider.on('chainChanged', () => {
      initialize(provider);
    });
  };

  const connect = useCallback(async () => {
    const provider = await web3Modal.connect();
    setWeb3ModalProvider(provider);

    initialize(provider);
    subscribeEvents(provider);
  }, [initialize, subscribeEvents]);

  const disconnect = async () => {
    if (web3ModalProvider?.close) {
      await web3ModalProvider.close();
    }
    web3Modal.clearCachedProvider();
    reset();
  };

  const handleNetwork = async () => {
    if (!cachedProvider) {
      await connect();
    } else {
      await disconnect();
    }
  };

  useEffect(() => {
    if (cachedProvider) {
      connect();
    }
  }, []);
  return {
    handleNetwork,
  };
}
export default useAuth;
