const { REACT_APP_BASE_API_URL } = process.env;

export const baseApiUrl = REACT_APP_BASE_API_URL || 'https://api.dzap.io/';

export const primaryChainId = 5;
export const secondaryChainId = 80001;
export const supportedChains: number[] = [primaryChainId, secondaryChainId];
export const GRAPH_API: { [key: number]: string } = {
  [primaryChainId]:
    'https://api.thegraph.com/subgraphs/name/dante055/zelato-goreli-tesnet-v1',
  [secondaryChainId]:
    'https://api.thegraph.com/subgraphs/name/dante055/zelato-polygon-tesnet-v1',
};
export const isTestingPools = true;
export const appVersion = 1;
export const estimateGasMultiplier = 2;
