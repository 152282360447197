import { BigNumber, ethers } from 'ethers';
import { DEFAULT_DECIMAL_PLACES } from '../Config/AppConstants';

export const camelCaseToSpaces = (str: string): string =>
  str
    .replace(/([A-Z][a-z0-9]+)/g, ' $1 ')
    .replace(/\s{2}/g, ' ')
    .trim();

export const stringValidation = {
  isNotEmpty(str: string) {
    const pattern = /\S+/;
    return pattern.test(str);
  },
  isNumber(str: string) {
    const pattern = /^\d+\.?\d*$/;
    return pattern.test(str);
  },
  isSame(str1: string, str2: string) {
    return str1 === str2;
  },
};

export const abbreviateNumber = (num: number, digits?: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((data) => num >= data.value);
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};

export const toHHMMSS = (secs: string) => {
  const secNum = parseInt(secs, 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;
  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? `0${v}` : v))
    .join(' : ');
};

export const toDDHHMMSS = (secs: string) => {
  const secNum = parseInt(secs, 10);
  const days = Math.floor(secNum / 86400);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;
  return [days, hours, minutes, seconds]
    .map((v) => (v < 10 ? `0${v}` : v))
    .join(' : ');
};

export const truncateDecimals = (
  number: number | string,
  index: number = DEFAULT_DECIMAL_PLACES,
) =>
  number.toString().indexOf('.') > 0
    ? number.toString().slice(0, number.toString().indexOf('.') + (index + 1))
    : number;

// Converts small unit to big unit => Like WEI to ETH -> 1000000 => 1 USDT, returns a String
export const currencyFormatter = (
  value: number | string | BigNumber,
  decimals = 18,
) =>
  truncateDecimals(
    ethers.utils.formatUnits(ethers.BigNumber.from(value.toString()), decimals),
    6,
  );

// Converts small unit to big unit => Like WEI to ETH -> 1000000 => 1 USDT, returns a String
export const abbreviateCurrency = (
  value: number | string | BigNumber,
  decimals = 18,
) =>
  abbreviateNumber(+currencyFormatter(value, decimals), DEFAULT_DECIMAL_PLACES);

export const formatSecondsToDHM = (seconds: number) => {
  const day = Math.floor(seconds / (3600 * 24));
  const hour = Math.floor((seconds % (3600 * 24)) / 3600);
  const minute = Math.floor((seconds % 3600) / 60);
  const dDisplay = day > 0 ? day + (day === 1 ? ' day, ' : ' day, ') : '';
  const hDisplay = hour > 0 ? hour + (hour === 1 ? ' hour, ' : ' hour, ') : '';
  const mDisplay = minute > 0 ? minute + (minute === 1 ? ' min, ' : ' min, ') : '';
  const outForDisplay = dDisplay + hDisplay + mDisplay;
  return outForDisplay.slice(0, -2) || (seconds > 0 ? `${seconds}S` : '0');
};

export const parseCurrencyInWei = (
  valueString: string | number,
  decimals = 18,
) => ethers.utils.parseUnits(valueString.toString(), decimals);

export const parseJsonString = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
};
