import {
  getExplorerAddressLink,
  getExplorerTransactionLink,
  shortenIfAddress,
} from '@usedapp/core';
import { hexStripZeros, hexlify } from 'ethers/lib/utils';
import { supportedChains } from '../Config/AppConfig';
import { METAMASK, STATUS } from '../Config/AppConstants';
import { CHAIN_INFO } from '../Config/ChainConfig';
import { errorNotification } from './NotificationUtils';

export const getAddressExplorerLink = (address: string, chainId: number) =>
  getExplorerAddressLink(address, chainId);
export const getHashExplorerLink = (hash: string, chainId: number) =>
  getExplorerTransactionLink(hash, chainId);

export const getChainInfoValue = (chainId: number, key: string | number) =>
  CHAIN_INFO[chainId] ? CHAIN_INFO[chainId][key] : undefined;

export const isSupportedChain = (chainId: number) =>
  supportedChains.includes(chainId);

export const shortAddress = (address: string | undefined) =>
  shortenIfAddress(address);
export const convertDecimalToHexString = (chainId: number) =>
  hexStripZeros(hexlify(chainId));

export const switchNetwork = async (chainId: number) => {
  try {
    const hexChainId = getChainInfoValue(chainId, 'hexChainId');
    const walletConnector = localStorage.getItem('walletConnector');
    if (walletConnector === METAMASK) {
      const { ethereum } = window;
      const res = await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }],
      });
      return res || STATUS.success;
    }
    errorNotification(
      'Switch Network',
      "Connector doesn't support chain switching!",
    );
    return 0;
  } catch (switchError: any) {
    errorNotification('Switch Network', switchError.message);
    return 0;
  }
};

export const isTestnet = (chainId: number) => [80001, 56].includes(chainId);
