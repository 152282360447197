import { Menu } from '@headlessui/react';
import unsupported from '../../Assets/Icons/unsupported.svg';
import { switchNetwork } from '../../Utils/ChainUtils';

function SwitchToProjectChain({ projectChainId }: { projectChainId: number }) {
  return (
    <Menu
      as="div"
      className="lg:block relative py-1 chain_selection_parent inline-block text-left"
    >
      <div>
        <Menu.Button
          onClick={() => switchNetwork(projectChainId)}
          className="chain_selection_unsupported rounded-xl h-10 flex justify-center items-center"
        >
          <div className="chain_selection_inner gap-x-2 rounded-xl w-full h-full flex justify-between items-center">
            <div className="flex items-center w-full justify-between gap-x-2.5">
              <div className="flex items-center gap-x-2.5">
                <img
                  src={unsupported}
                  alt="chain"
                  className="w-3 h-3 object-contain"
                />
                <p className="chain_selection_inner_text">
                  SWITCH CHAIN
                </p>
              </div>
              {/* <img
                src={dropdownArrow}
                alt="dropdown"
                className="w-3 h-3 object-contain chain_selection_dropdown rotate-180"
              /> */}
            </div>
          </div>
        </Menu.Button>
      </div>
    </Menu>
  );
}
export default SwitchToProjectChain;
