import chumbiValley from '../../../../../Assets/Images/chumbi-valley-dummy.svg';
import { useAppSelector } from '../../../../../Hooks/state';
import { RootState } from '../../../../../Store';

type NftListProps = {
  amount: string;
  nftId: string;
};

export default function ClaimReceipt() {
  const { trxResponse } = useAppSelector((state: RootState) => state.common);
  const {
    poolInfo: { type },
    nfts: { data },
  } = useAppSelector((state: RootState) => state.poolInfo);
  const { args } =
    trxResponse.data.events.find((item: any) => item.event === 'NftClaimed') ||
    {};
  const nftData: NftListProps[] = [];
  args.nftIds.forEach((a: any, i: number) => {
    nftData.push({
      amount: args?.amount?.[i]?.toString() || '0',
      nftId: args.nftIds[i]?.toString(),
    });
  });
  function getUniqueListBy(arr: NftListProps[]) {
    return [
      ...new Map(arr.map((item: NftListProps) => [item.nftId, item])).values(),
    ];
  }
  const filteredNftList = getUniqueListBy(nftData);
  return (
    <div
      className="overflow-y-auto side_bar flex flex-wrap pt-2.5 gap-x-5 gap-y-8 items-center justify-center"
      style={{ height: 296 }}
    >
      {filteredNftList
        ?.sort((a, b) => +a.nftId - +b.nftId)
        ?.map(({ nftId }) => {
          const { image } = data.find(({ id }) => id.toString() === nftId) || {
            image: chumbiValley,
          };
          const totalAmount = nftData.filter(
            (singleNft) => singleNft.nftId === nftId,
          ).length;
          return (
            <div className="relative reward_popup_success_2" key={nftId}>
              <img src={image} className="h-140px w-140px" alt="nft" />
              {type === 1 && (
                <div
                  className="absolute -top-2.5 z-10 right-0 px-2 flex justify-center items-center"
                  style={{
                    height: 18,
                    background:
                      'linear-gradient(180deg, #00333F 0%, #000000 100%)',
                    borderRadius: 12,
                  }}
                >
                  x{totalAmount}
                </div>
              )}
              <div
                style={{
                  color: '#61FFD0',
                }}
                className="absolute bottom-2.5 left-2.5 h-6 px-1.5 flex justify-center items-center rounded bg-black font-semibold text-sm"
              >
                #{nftId}
              </div>
            </div>
          );
        })}
    </div>
  );
}
