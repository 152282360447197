export const FILTER = {
  sortBy: 'SORT BY',
  newestToOldest: 'Newest to Oldest',
  imageAlt: 'ice-cream',
};

export const TOTAL_NFTS_IN_POOL = 'Total NFTS in Pool';
export const TOTAL_LOCKED_VALUE = 'Total Value Locked';
export const TOTAL_REWARDS_DISTRIBUTED = 'Total Rewards Distributed';
export const EMISSION_PER_DAY = 'NFT Emission per day';
export const UNBONDING_PERIOD = 'Unbonding Period';
export const MINIMUM_STAKE = 'Minimum Stake';
export const TOKEN = 'TOKEN';
export const CHAIN = 'CHAIN';
export const ENTER_POOL = 'ENTER POOL';
export const STAKED_ONLY = 'STAKED ONLY';
export const PROJECT_HEADING =
  'Lorem Ipsum. Browse through our exclusive Pools & stake tokens inprojects to recieve Game NFTs.';
export const BACK = 'BACK';

export const POOL_TAB = {
  inProgress: 'inProgress',
  completed: 'completed',
  stakedOnly: 'stakedOnly',
};

export const LS_KEY_POOLS = 'pools';
