import Nfts from './Nfts';
import PoolInfo from './PoolInfo';

function NftsComponents() {
  return (
    <div>
      <PoolInfo />
      <Nfts />
    </div>
  );
}
export default NftsComponents;
