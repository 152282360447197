import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import poolInfoReducer from '../Logic/PoolInfo/Store/Reducer';
import poolsReducer from '../Logic/Pools/Store/Reducer';
import authReducer from './AuthReducer';
import commonReducer from './CommonReducer';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    pool: poolsReducer,
    poolInfo: poolInfoReducer,
    auth: authReducer,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
