function Input({
  value,
  max,
  onChange,
}: {
  value: string;
  max: number;
  onChange: Function;
}) {
  return (
    <div className="flex justify-center items-center mb-2">
      <div className="modal_staking_table relative">
        <div className="absolute modal_staking_table_inner flex md:pl-5 pl-2 items-center">
          <p className="modal_staking_table_name">SET STAKE</p>
        </div>
      </div>
      <div className="modal_staking_table relative">
        <div className="absolute modal_staking_table_inner gap-x-2 flex justify-center items-center">
          <input
            type="number"
            name="staking-amount"
            className="modal_staking_table_input p-1 text-center"
            placeholder="Enter"
            max={max}
            onChange={(e) => {
              onChange(+e.target.value);
            }}
            value={value}
          />
          <button
            type="button"
            onClick={() => onChange(max)}
            className="max_btn"
          >
            MAX
          </button>
        </div>
      </div>
    </div>
  );
}
export default Input;
