export const METAMASK = 'metamask';
export const DEFAULT_DECIMAL_PLACES = 4;
export const DEFAULT_CHAIN_ID = 80001;
export const LS_KEY_VIEW_TYPE = 'viewType';
export const LS_KEY_ACTIVE_TAB = 'activeTab';

export const STATUS = {
  pending: 'Pending',
  inProgress: 'InProgress',
  success: 'Success',
  rejected: 'Rejected',
  error: 'Error',
};
