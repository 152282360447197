import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import modalClose from '../../Assets/Images/modal-close.svg';
import { Actions, TrxState } from '../../Enums';
import { useAppSelector } from '../../Hooks/state';
import { AppDispatch, RootState } from '../../Store';
import {
  setAction,
  setTrxResponse,
  setTrxState,
} from '../../Store/CommonReducer';

function CommonModal({
  heading,
  description,
  children,
  disableClose,
}: {
  heading: string;
  description: string;
  children: ReactNode;
  disableClose?: boolean;
}) {
  const { trxResponse } = useAppSelector((state: RootState) => state.common);
  const dispatch = useDispatch<AppDispatch>();
  const handleCancel = () => {
    if (!disableClose) {
      dispatch(setAction(Actions.unset));
      dispatch(setTrxState(TrxState.unset));
      if (trxResponse.status !== '') {
        dispatch(setTrxResponse({ status: '', data: {} }));
      }
    }
  };
  return (
    <Transition.Root show as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => handleCancel()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-1 md:p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="staking_modal">
                <div className="staking_modal_inner flex flex-col justify-between py-5 px-0 md:px-5 md:py-5 pb-16 absolute">
                  {!disableClose && (
                    <div className="flex w-full px-4 md:px-0 justify-end items-center">
                      <button type="button" onClick={() => handleCancel()}>
                        <img
                          src={modalClose}
                          className="staking_modal_close"
                          alt="close"
                        />
                      </button>
                    </div>
                  )}
                  <div className="w-full flex justify-center items-end text_primary h-full">
                    <div className="flex items-center flex-col w-full md:w-auto h-full">
                      <div className="flex flex-col justify-center mt-10 items-center">
                        <p className="staking_modal_approval_heading">
                          {heading}
                        </p>
                        <p className="staking_modal_approval_desc">
                          {description}
                        </p>
                      </div>
                      {children}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
CommonModal.defaultProps = {
  disableClose: false,
};
export default CommonModal;
