import { useDispatch } from 'react-redux';
import { STATUS } from '../Config/AppConstants';
import { TrxState } from '../Enums';
import { AppDispatch, RootState } from '../Store';
import { setTrxState } from '../Store/CommonReducer';
import { initializeERC20TokenContract } from '../Utils/ContractUtils';
import { parseCurrencyInWei } from '../Utils/GeneralUtils';
import {
  errorNotification,
  successNotification,
} from '../Utils/NotificationUtils';
import { useAppSelector } from './state';

function useApprove() {
  const { readWriteProvider, account } = useAppSelector(
    (state: RootState) => state.auth,
  );
  const { poolInfo } = useAppSelector((state: RootState) => state.poolInfo);
  const dispatch = useDispatch<AppDispatch>();

  const approve = async (contract: string, isRemove = false) => {
    try {
      dispatch(setTrxState(TrxState.processing));
      const contractAddress = poolInfo.address;
      const tokenContract = initializeERC20TokenContract({
        tokenAddress: contract,
        provider: readWriteProvider,
      });
      const approvalResult = await tokenContract.approve(
        contractAddress,
        isRemove ? 0 : await tokenContract.totalSupply(),
      );
      dispatch(setTrxState(TrxState.pending));
      const res = await approvalResult.wait();
      successNotification(
        'Approve',
        `${await tokenContract.symbol()} ${
          isRemove ? 'Approval removed' : 'approved'
        } successfully`,
      );
      dispatch(setTrxState(TrxState.unset));
      return { status: STATUS.success, data: res };
    } catch (err: any) {
      errorNotification('Approve', err?.message);
      dispatch(setTrxState(TrxState.unset));
      return { status: STATUS.error, data: err };
    }
  };

  const mint = async (contract: string) => {
    try {
      dispatch(setTrxState(TrxState.processing));
      const tokenContract = initializeERC20TokenContract({
        tokenAddress: contract,
        provider: readWriteProvider,
      });
      const trx = await tokenContract.mint(account, parseCurrencyInWei(10000));
      dispatch(setTrxState(TrxState.pending));
      const res = await trx.wait();
      successNotification('Mint', 'Transaction processed successfully');
      dispatch(setTrxState(TrxState.unset));
      return { status: STATUS.success, data: res };
    } catch (err: any) {
      errorNotification('Mint', err?.message);
      dispatch(setTrxState(TrxState.unset));
      return { status: STATUS.error, data: err };
    }
  };
  return {
    approve,
    mint,
  };
}
export default useApprove;
