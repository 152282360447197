import { BigNumber } from 'ethers';
import {
  currencyFormatter,
  parseJsonString,
} from '../../../Utils/GeneralUtils';
import { LS_KEY_NFTS } from '../Constants';

export const computeNFTReleaseTime = (
  stakeAmount: BigNumber,
  totalStakedAmount: BigNumber,
  rewardPerSec: BigNumber,
  unclaimedRewards: BigNumber,
  decimals: number = 18,
) => {
  try {
    const stakeAmountInInt = +currencyFormatter(stakeAmount, decimals);
    const totalStakedAmountInInt = +currencyFormatter(
      totalStakedAmount,
      decimals,
    );
    const rewardPerSecInInt = +currencyFormatter(rewardPerSec, decimals);
    const unclaimedRewardsInInt = +currencyFormatter(
      unclaimedRewards,
      decimals,
    );
    const netWeightage = 1;
    const userEmissionRatePerSec =
      (stakeAmountInInt / totalStakedAmountInInt) * rewardPerSecInInt;
    const pendingReward = netWeightage - (unclaimedRewardsInInt % netWeightage);
    return userEmissionRatePerSec > 0
      ? pendingReward / userEmissionRatePerSec
      : 0;
  } catch (error) {
    return 0;
  }
};

export const getAllCachedNfts = (projectId: string) =>
  parseJsonString(
    localStorage.getItem(`all_${LS_KEY_NFTS}_${projectId}`) || '',
  );

export const cacheAllNfts = (nfts: any, projectId: string) =>
  localStorage.setItem(`all_${LS_KEY_NFTS}_${projectId}`, JSON.stringify(nfts));

export const getCachedNfts = (projectId: string) =>
  parseJsonString(localStorage.getItem(`${LS_KEY_NFTS}_${projectId}`) || '');

export const cacheNfts = (nfts: any, projectId: string) =>
  localStorage.setItem(`${LS_KEY_NFTS}_${projectId}`, JSON.stringify(nfts));

export const getProjectDetailPath = (chainId: number, projectId: string) =>
  window.open(`/pool/${chainId}/${projectId}`, '_self');
