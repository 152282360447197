import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import { Pool } from '../../Pools/Types/PoolRowType';
import { POOL_INFO } from '../Constants';

export type NftType = {
  loading: boolean;
  data: { id: number; image: string; balance: number }[];
};

export type StakerInfoType = {
  exist: boolean;
  staked: BigNumber;
  totalNftsClaimed: BigNumber;
  unclaimedRewards: BigNumber;
  unstakeTime: BigNumber;
  isLoading: boolean;
};

export interface Props {
  poolInfo: Pool;
  stakerInfo: StakerInfoType;
  stakingBalance: BigNumber;
  nfts: NftType;
}

const initialState: Props = {
  stakingBalance: BigNumber.from('0'),
  poolInfo: POOL_INFO,
  stakerInfo: {
    exist: false,
    staked: BigNumber.from('0'),
    totalNftsClaimed: BigNumber.from('0'),
    unclaimedRewards: BigNumber.from('0'),
    unstakeTime: BigNumber.from('0'),
    isLoading: true,
  },
  nfts: { loading: true, data: [] },
};
export const poolInfo = createSlice({
  name: 'poolInfo',
  initialState,
  reducers: {
    setPoolInfo: (state, action: PayloadAction<Pool>) => {
      const currentState = state;
      currentState.poolInfo = action.payload;
    },
    setStakerInfo: (state, action: PayloadAction<StakerInfoType>) => {
      const currentState = state;
      currentState.stakerInfo = action.payload;
    },
    setStakingBalance: (state, action: PayloadAction<BigNumber>) => {
      const currentState = state;
      currentState.stakingBalance = action.payload;
    },
    setNfts: (state, action: PayloadAction<NftType>) => {
      const currentState = state;
      currentState.nfts = action.payload;
    },
  },
});

export const { setPoolInfo, setNfts, setStakerInfo, setStakingBalance } =
  poolInfo.actions;

export default poolInfo.reducer;
