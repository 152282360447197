import './App.css';
import useThemeDetector from './Components/Hooks/useThemeDetector';
import AppRoutes from './Routes/AppRoutes';

function App() {
  return (
    <div data-theme={useThemeDetector()}>
      <AppRoutes />
    </div>
  );
}

export default App;
