import CommonModal from '../../../../../Components/Misc/CommonModal';
import { useAppSelector } from '../../../../../Hooks/state';
import { RootState } from '../../../../../Store';

function BuyTokens() {
  const {
    poolInfo: { exchanges },
  } = useAppSelector((state: RootState) => state.poolInfo);
  return (
    <CommonModal
      heading="OOPS ! NO TOKENS AVAILABLE"
      description="YOUR WALLET DOES NOT HOLD $CHMB TOKENS. BUY TOKENS TO ENTER THE POOL"
    >
      <div className="flex flex-col gap-y-2.5 justify-center items-center">
        {exchanges?.map(({ name, link }) => (
          <button
            type="button"
            key={name}
            onClick={() => window.open(link)}
            className="staking_modal_approval_btn2"
          >
            <p className="staking_modal_approval_btn_text">{name}</p>
          </button>
        ))}
      </div>
    </CommonModal>
  );
}
export default BuyTokens;
