import img404 from '../../../Assets/Images/404.svg';
import view from '../../../Assets/Images/view.svg';
import Spinner from '../../../Components/Misc/Spinner';
import { useAppSelector } from '../../../Hooks/state';
import { RootState } from '../../../Store';

function Nfts() {
  const {
    nfts,
    poolInfo: {
      nftDetails: { nftExplorer },
    },
  } = useAppSelector((state: RootState) => state.poolInfo);
  return (
    <div className="w-full total_nfts_main">
      <h2 className="w-full text-center total_nfts_main_heading">
        NFTS IN POOL
      </h2>
      <div className="flex justify-center items-center gap-x-4 sm:gap-x-10 mt-7 mb-10 sm:mb-0 sm:mt-14 gap-y-6 sm:gap-y-14 flex-wrap">
        {nfts.data.map(({ id, image, balance }) => (
          <div key={id} className="total_nfts_img_container">
            <div className="total_nfts_img_container_child relative">
              <img
                src={image}
                className="w-full h-full object-contain"
                alt={`${id}`}
              />
              <div
                style={{
                  color: '#61FFD0',
                }}
                className="absolute bottom-2.5 left-2.5 h-6 px-1.5 flex justify-center items-center rounded bg-black font-semibold text-sm"
              >
                #{id}
              </div>
            </div>
            <div className="w-full flex justify-between items-center mt-3 sm:mt-4 sm:pt-0.5">
              {balance !== 1 && (
                <p className="total_nfts_img_container_text">Qty: {balance}</p>
              )}
              <button
                onClick={() => {
                  window.open(`${nftExplorer}${id}`);
                }}
                className="h-5 rounded-lg border border-white flex justify-center total_nfts_img_container_text_btn w-14 items-center"
                type="button"
              >
                <p>VIEW</p>
                <img src={view} alt="view" className="h-2.5 object-contain" />
              </button>
            </div>
          </div>
        ))}
        {nfts.loading ? (
          <Spinner />
        ) : (
          <div>
            {nfts.data.length === 0 && (
              <div className="page_404_right">
                <img
                  src={img404}
                  className="w-full h-full object-contain"
                  alt="404"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
export default Nfts;
