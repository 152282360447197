/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { BigNumber, ethers, providers } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import erc1155Abi from '../Artifacts/Erc/ERC1155.json';
import erc20Abi from '../Artifacts/Erc/ERC20.json';
import erc721Abi from '../Artifacts/Erc/ERC721.json';
import { JSON_RPC_PROVIDER } from '../Config/ChainConfig';

export type ProviderType =
  | ethers.providers.Provider
  | ethers.Signer
  | undefined;
export const getChecksumAddress = (address: string) =>
  ethers.utils.getAddress(address);

export const readWriteProvider = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  return provider;
};

export const isAddress = (address: string) => {
  try {
    return ethers.utils.getAddress(address);
  } catch {
    return false;
  }
};
export const initializeReadOnlyProvider = (chainId: number) => {
  if (JSON_RPC_PROVIDER[chainId]) {
    return new providers.JsonRpcProvider(JSON_RPC_PROVIDER[chainId]);
  }
  return providers.getDefaultProvider();
};

export const initializeERC20TokenContract = ({
  tokenAddress,
  provider,
}: {
  tokenAddress: string;
  provider: ProviderType;
}) =>
  new ethers.Contract(getChecksumAddress(tokenAddress), erc20Abi.abi, provider);

export const initializeERC721TokenContract = ({
  tokenAddress,
  provider,
}: {
  tokenAddress: string;
  provider: ProviderType;
}) =>
  new ethers.Contract(
    getChecksumAddress(tokenAddress),
    erc721Abi.abi,
    provider,
  );

export const initializeERC1155TokenContract = ({
  tokenAddress,
  provider,
}: {
  tokenAddress: string;
  provider: ProviderType;
}) =>
  new ethers.Contract(
    getChecksumAddress(tokenAddress),
    erc1155Abi.abi,
    provider,
  );

export const initializeContract: ({
  contract,
  abiPath,
  provider,
}: {
  contract: string;
  abiPath: string;
  provider?: ProviderType;
}) => ethers.Contract = ({ contract, abiPath, provider }) => {
  // eslint-disable-next-line global-require
  const ABI = require(`../Artifacts/${abiPath}`).abi;
  return new ethers.Contract(getChecksumAddress(contract), ABI, provider);
};

export const parseUnitsInWei = (amount: number | string, decimals: number) =>
  parseUnits(amount.toString(), decimals).toString();

export const getReadWriteProvider = (provider: any) => {
  const ethersProvider = new providers.Web3Provider(provider);
  return ethersProvider.getSigner(0);
};

export const parseCurrencyInWeb2Format = (
  value: BigNumber | string,
  decimals: number,
) =>
  ethers.utils.formatUnits(ethers.BigNumber.from(value.toString()), decimals);
