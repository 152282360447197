import Countdown, { zeroPad } from 'react-countdown';

export default function CountdownTimer({
  timestamp,
  onComplete,
  onStart,
  classNames,
}: {
  timestamp: number;
  onComplete: Function;
  onStart: Function;
  classNames?: string;
}) {
  const renderer = ({ days, hours, minutes, seconds }: any) => (
    <div>
      {classNames ? (
        <div className={classNames ? 'flex' : ''}>
          <div className={classNames}>
            {zeroPad(days)}
            <div className="coundown-label">DAYS</div>
          </div>
          <div className={classNames}>
            {zeroPad(hours)}
            <div className="coundown-label">HOURS</div>
          </div>
          <div className={classNames}>
            {zeroPad(minutes)}
            <div className="coundown-label">MINUTES</div>
          </div>
          <div className={classNames}>
            {zeroPad(seconds)}
            <div className="coundown-label">SECONDS</div>
          </div>
        </div>
      ) : (
        <div>
          {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </div>
      )}
    </div>
  );
  return (
    <Countdown
      onComplete={() => onComplete()}
      onStart={() => onStart()}
      date={timestamp}
      renderer={renderer}
    />
  );
}
CountdownTimer.defaultProps = {
  classNames: '',
};
