import { useDispatch } from 'react-redux';
import { appVersion, estimateGasMultiplier } from '../../../Config/AppConfig';
import { STATUS } from '../../../Config/AppConstants';
import { CONTRACT_HISTORY } from '../../../Constants';
import { TrxState } from '../../../Enums';
import { useAppSelector } from '../../../Hooks/state';
import { AppDispatch, RootState } from '../../../Store';
import {
  setIsRefreshData,
  setTrxResponse,
  setTrxState,
} from '../../../Store/CommonReducer';
import { initializeContract } from '../../../Utils/ContractUtils';
import {
  parseCurrencyInWei,
  truncateDecimals,
} from '../../../Utils/GeneralUtils';

function useStake() {
  const { readWriteProvider } = useAppSelector(
    (state: RootState) => state.auth,
  );
  const { poolInfo } = useAppSelector((state: RootState) => state.poolInfo);

  const dispatch = useDispatch<AppDispatch>();

  const stake = async (amount: number) => {
    // let title;
    // let message;
    // if (action === Actions.cancelWithdraw) {
    //   title = 'Cancel Withdraw';
    //   message = 'Withdrawal has been cancelled successfully!';
    // } else {
    //   title = 'Stake';
    //   message = 'Staking received successfully';
    // }
    try {
      const {
        type,
        tokenDetails: { decimals },
      } = poolInfo;
      dispatch(setTrxState(TrxState.processing));
      const contractAddress = poolInfo.address;
      const abiPath = CONTRACT_HISTORY[appVersion][`type${type}`];
      const contract = initializeContract({
        contract: contractAddress,
        abiPath,
        provider: readWriteProvider,
      });
      const amountInWei = parseCurrencyInWei(
        truncateDecimals(amount),
        decimals,
      );
      const estimateGas = await contract.estimateGas.stake(amountInWei);
      const trx = await contract.stake(amountInWei, {
        gasLimit: estimateGas.mul(estimateGasMultiplier),
      });
      dispatch(setTrxState(TrxState.pending));
      const res = await trx.wait();
      //   successNotification(title, message);
      dispatch(setTrxState(TrxState.completed));
      dispatch(setIsRefreshData(true));
      const response = { status: STATUS.success, data: res };
      dispatch(setTrxResponse(response));
      return response;
    } catch (err: any) {
      //   errorNotification(title, err?.message);
      dispatch(setTrxState(TrxState.error));
      return { status: STATUS.error, data: err };
    }
  };
  return {
    stake,
  };
}
export default useStake;
