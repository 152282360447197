import { useParams } from 'react-router-dom';
import { RootState } from '../../../Store';

import { appVersion } from '../../../Config/AppConfig';
import { CONTRACT_HISTORY } from '../../../Constants';
import { useAppDispatch, useAppSelector } from '../../../Hooks/state';
import { setAllowance } from '../../../Store/CommonReducer';
import {
  initializeContract,
  initializeERC20TokenContract,
  initializeReadOnlyProvider,
} from '../../../Utils/ContractUtils';
import { setStakerInfo, setStakingBalance } from '../Store/Reducer';

export default function useStakerDetails() {
  const { poolInfo } = useAppSelector((state: RootState) => state.poolInfo);

  const { account } = useAppSelector((state: RootState) => state.auth);
  const { chainId } = useParams();
  const projectChainId = +(chainId || 0);
  const dispatch = useAppDispatch();
  const provider = initializeReadOnlyProvider(projectChainId);
  const { address, type } = poolInfo;

  const getStakerDetails = async (fetchAllowance = false) => {
    const abiPath = CONTRACT_HISTORY[appVersion][`type${type}`];
    const contract = initializeContract({
      contract: address,
      abiPath,
      provider,
    });
    const { exist, staked, totalNftsClaimed, unclaimedRewards, unstakeTime } =
      await contract.getStakerInfo(account);
    const erc20Contract = initializeERC20TokenContract({
      provider,
      tokenAddress: poolInfo.tokenDetails.address,
    });
    dispatch(setStakingBalance(await erc20Contract.balanceOf(account)));
    dispatch(
      setStakerInfo({
        exist,
        staked,
        totalNftsClaimed,
        unclaimedRewards,
        unstakeTime,
        isLoading: false,
      }),
    );
    if (fetchAllowance) {
      dispatch(
        setAllowance(
          (await erc20Contract.allowance(account, address)).toString(),
        ),
      );
    }
  };

  return {
    getStakerDetails,
  };
}
