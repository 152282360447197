import { isTestingPools } from '../../../Config/AppConfig';
import poolData from '../../../Config/Data/Pools.json';
import testPoolData from '../../../Config/Data/TestPools.json';
import { parseJsonString } from '../../../Utils/GeneralUtils';
import { LS_KEY_POOLS } from '../Constants';
import { Pool } from '../Types/PoolRowType';

const data = isTestingPools ? testPoolData : poolData;

export const getPools = () => data;
export const getProject = (chainId: number, projectId: number) =>
  (data as any)[chainId][projectId];

export const getCachedPools = (): Pool[] =>
  parseJsonString(localStorage.getItem(`${LS_KEY_POOLS}`) || '[]');

export const setLSPools = (pools: any) =>
  localStorage.setItem(`${LS_KEY_POOLS}`, JSON.stringify(pools));
