/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Actions, Tabs, TrxState, ViewType } from '../Enums';
import { getCachedActiveTab, getCachedViewType } from '../Utils/AppUtils';

export interface TokensPriceType {
  contract: string;
  price: string;
}

type Props = {
  trxResponse: any;
  isRefreshData: boolean;
  isApproved: boolean;
  isLoading: boolean;
  isChainSwitcher: boolean;
  trxState: number;
  tokensPrice: TokensPriceType[];
  allowance: string;
  isApprovalModal: boolean;
  closable: boolean;
  requiredGasFee: number;
  viewType: string;
  activeTab: string;
  searchKey: string;
  action: string;
  nativeBalance: string;
};

const initialState: Props = {
  trxResponse: undefined,
  isRefreshData: false,
  isApproved: false,
  isLoading: false,
  isChainSwitcher: false,
  trxState: TrxState.unset,
  tokensPrice: [],
  allowance: '0',
  isApprovalModal: false,
  closable: false,
  requiredGasFee: 0,
  viewType: getCachedViewType() || ViewType.list,
  activeTab: getCachedActiveTab() || Tabs.inProgress,
  searchKey: '',
  action: Actions.unset,
  nativeBalance: '0',
};

export const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setNativeBalance: (state, action: PayloadAction<string>) => {
      state.nativeBalance = action.payload;
    },
    setTrxResponse: (state, action: PayloadAction<any>) => {
      const currentState = state;
      currentState.trxResponse = action.payload;
    },
    setIsRefreshData: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isRefreshData = action.payload;
    },
    setIsApproved: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isApproved = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isLoading = action.payload;
    },
    setTrxState: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.trxState = action.payload;
    },
    setTokensPrice: (state, action: PayloadAction<TokensPriceType[]>) => {
      const currentState = state;
      currentState.tokensPrice = action.payload;
    },
    setAllowance: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.allowance = action.payload;
    },
    setIsApprovalModal: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isApprovalModal = action.payload;
    },
    setClosable: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.closable = action.payload;
    },
    setRequiredGasFee: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.requiredGasFee = action.payload;
    },
    setViewType: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.viewType = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.activeTab = action.payload;
    },
    setSearchKey: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.searchKey = action.payload;
    },
    setAction: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.action = action.payload;
    },
  },
});

export const {
  setNativeBalance,
  setTrxResponse,
  setIsRefreshData,
  setIsApproved,
  setIsLoading,
  setTrxState,
  setTokensPrice,
  setIsApprovalModal,
  setAllowance,
  setClosable,
  setRequiredGasFee,
  setViewType,
  setSearchKey,
  setActiveTab,
  setAction,
} = common.actions;

export default common.reducer;
