import { Menu, Transition } from '@headlessui/react';
import { message } from 'antd';
import { Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copy from '../../Assets/Icons/copy.svg';
import dropdownArrow from '../../Assets/Icons/dropdown-arrow.svg';
import explorer from '../../Assets/Icons/explorer.svg';
import logout from '../../Assets/Icons/logout.svg';
import { useAppSelector } from '../../Hooks/state';
import useAuth from '../../Hooks/useAuth';
import { RootState } from '../../Store';
import { getAddressExplorerLink, shortAddress } from '../../Utils/ChainUtils';
import { abbreviateCurrency } from '../../Utils/GeneralUtils';

export default function WalletInfo() {
  const { account, chainId } = useAppSelector((state: RootState) => state.auth);
  const { nativeBalance } = useAppSelector((state: RootState) => state.common);
  const { handleNetwork } = useAuth();

  return (
    <Menu
      as="div"
      className="relative py-1 chain_selection_parent inline-block text-left"
    >
      <div>
        <Menu.Button className="rounded-xl flex items-center">
          <div className="chain_selection_wallet h-10 rounded-l-xl flex justify-start items-center">
            <div className="chain_selection_inner_wallet gap-x-2 rounded-l-xl h-full flex justify-between items-center">
              <div className="flex items-center w-full justify-between gap-x-2.5">
                <p className="chain_selection_inner_text_wallet">
                  {shortAddress(account)}
                </p>
                <img
                  src={dropdownArrow}
                  alt="dropdown"
                  className="w-3 h-3 object-contain chain_selection_dropdown rotate-180"
                />
              </div>
            </div>
          </div>
          <button
            type="button"
            className="h-10 w-10 rounded-r-xl bg-white chain_selection_inner_wallet_right flex justify-center items-center"
            onClick={handleNetwork}
          >
            <img src={logout} className="w-5" alt="logout" />
          </button>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-1 left-0 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Items className="absolute chain_selection_box_wallet right-0 left-0">
            <Menu.Item>
              <div className="py-4 px-3 flex h-full flex-col justify-between items-center">
                <div className="w-full border chain_selection_box_wallet_inner text-white border-white h-7 px-2.5 flex items-center justify-between">
                  <p>{shortAddress(account)}</p>
                  <div className="flex justify-center items-center gap-x-2">
                    <CopyToClipboard
                      text={account}
                      onCopy={() => {
                        message.success({
                          content: 'Copied!',
                        });
                      }}
                    >
                      <img
                        src={copy}
                        className="h-3 w-3 object-contain cursor-pointer"
                        alt="copy"
                      />
                    </CopyToClipboard>
                    <button
                      type="button"
                      onClick={() => {
                        window.open(
                          getAddressExplorerLink(account || '', chainId),
                          '_blank',
                        );
                      }}
                    >
                      <img
                        src={explorer}
                        className="h-3 w-3 object-contain"
                        alt="explorer"
                      />
                    </button>
                  </div>
                </div>
                <div className="w-full">
                  <p className="chain_selection_box_wallet_inner_balance">
                    BALANCE
                  </p>
                  <div className="w-full mt-1 border chain_selection_box_wallet_inner text-white border-white h-7 px-2.5 flex items-center justify-between">
                    <p>{abbreviateCurrency(nativeBalance, 18)} MATIC</p>
                  </div>
                </div>
              </div>
            </Menu.Item>
          </Menu.Items>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
