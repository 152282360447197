import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NftsPage from './Nfts';

const PoolsPage = React.lazy(() => import('./Pools'));
const PoolInfoPage = React.lazy(() => import('./PoolInfo'));
const Homepage = React.lazy(() => import('./Homepage'));
function AppRoutes(): React.ReactElement {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<>...</>}>
            <Homepage />
          </React.Suspense>
        }
      />
      <Route
        path="/pools"
        element={
          <React.Suspense fallback={<>...</>}>
            <PoolsPage />
          </React.Suspense>
        }
      />
      <Route
        path="/pool/:chainId/:projectId"
        element={
          <React.Suspense fallback={<>...</>}>
            <PoolInfoPage />
          </React.Suspense>
        }
      />
      <Route
        path="/nfts/:chainId/:projectId"
        element={
          <React.Suspense fallback={<>...</>}>
            <NftsPage />
          </React.Suspense>
        }
      />
    </Routes>
  );
}

export default AppRoutes;
