import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pool } from '../Types/PoolRowType';
import { getCachedPools } from '../Utils';

export interface Props {
  tvl: number;
  selectedSortValue: string;
  pools: Pool[];
  stakerPools: string[];
  isLoadingStakersPools: boolean;
}

const initialState: Props = {
  tvl: 0,
  selectedSortValue: 'Newest to Oldest',
  pools: getCachedPools() || [],
  stakerPools: [],
  isLoadingStakersPools: true,
};
export const poolReducer = createSlice({
  name: 'poolReducer',
  initialState,
  reducers: {
    setSelectedSortValue: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.selectedSortValue = action.payload;
    },
    setPools: (state, action: PayloadAction<Pool[]>) => {
      const currentState = state;
      currentState.pools = action.payload;
    },
    setTvl: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.tvl = action.payload;
    },
    setStakerPools: (state, action: PayloadAction<string[]>) => {
      const currentState = state;
      currentState.stakerPools = action.payload;
    },
    setIsLoadingStakersPools: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isLoadingStakersPools = action.payload;
    },
  },
});

export const {
  setSelectedSortValue,
  setPools,
  setTvl,
  setStakerPools,
  setIsLoadingStakersPools,
} = poolReducer.actions;

export default poolReducer.reducer;
