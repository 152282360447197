import '../../Assets/Css/NftDetails.css';
import '../../Assets/Css/Project.css';
import AppLayout from '../../Components/Layouts';
import useInitProjectDetails from '../../Logic/PoolInfo/Hooks/useInitPoolInfo';
import NftsComponents from './Components';

function NftsPage() {
  useInitProjectDetails();
  return (
    <AppLayout>
      <NftsComponents />
    </AppLayout>
  );
}

export default NftsPage;
