import {
  DEFAULT_CHAIN_ID,
  LS_KEY_ACTIVE_TAB,
  LS_KEY_VIEW_TYPE,
} from '../Config/AppConstants';
import { isSupportedChain } from './ChainUtils';

export const getDefaultChainId = () => {
  const chainId = Number(localStorage.getItem('chainId'));
  return isSupportedChain(chainId) ? chainId : DEFAULT_CHAIN_ID;
};

export const getCachedAccount = () => localStorage.getItem('account') || '';

export const getCachedViewType = () =>
  localStorage.getItem(LS_KEY_VIEW_TYPE) || '';

export const cacheViewType = (view: string) =>
  localStorage.setItem(LS_KEY_VIEW_TYPE, view);

export const getCachedActiveTab = () =>
  localStorage.getItem(LS_KEY_ACTIVE_TAB) || '';

export const cacheActiveTab = (tab: string) =>
  localStorage.setItem(LS_KEY_ACTIVE_TAB, tab);
