function Info({ symbol, balance }: { symbol: string; balance: number }) {
  return (
    <div>
      <div className="flex justify-center items-center">
        <div className="modal_staking_table relative">
          <div className="absolute modal_staking_table_inner flex md:pl-5 pl-2 items-center">
            <p className="modal_staking_table_name">TOKEN</p>
          </div>
        </div>
        <div className="modal_staking_table relative">
          <div className="absolute modal_staking_table_inner flex justify-center items-center">
            <p className="modal_staking_table_value">{symbol || ''}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="modal_staking_table relative">
          <div className="absolute modal_staking_table_inner flex md:pl-5 pl-2 items-center">
            <p className="modal_staking_table_name">AVAILABLE IN WALLET</p>
          </div>
        </div>
        <div className="modal_staking_table relative">
          <div className="absolute modal_staking_table_inner flex justify-center items-center">
            <p className="modal_staking_table_value">{balance}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Info;
