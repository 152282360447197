import { useDispatch } from 'react-redux';
import { Actions } from '../../../Enums';
import { useAppSelector } from '../../../Hooks/state';
import { getProjectDetailPath } from '../../../Logic/PoolInfo/Utils';
import { AppDispatch, RootState } from '../../../Store';
import { setAction } from '../../../Store/CommonReducer';
import StakeModal from '../../PoolInfo/Components/Actions/Staking';

function PoolInfo() {
  const {
    poolInfo,
    stakerInfo: { isLoading },
  } = useAppSelector((state: RootState) => state.poolInfo);
  const { action } = useAppSelector((state: RootState) => state.common);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="w-full nft_deatils_main flex flex-wrap lg:flex-nowrap lg:justify-between items-center">
      <div className="project_info_main p-0 flex justify-start gap-x-7 sm:gap-x-10 sm:items-center items-start w-full">
        <div className="project_info_main_img mt-2">
          <img
            src={poolInfo.image}
            className="w-full sm:h-full sm:object-contain"
            alt="project-cone"
          />
        </div>
        <div className="project_info_container">
          <h1 className="project_info_name w-full">{poolInfo.name || ''}</h1>
          <div className="project_info_divider w-full" />
          <p className="project_info_description w-full">
            {poolInfo.description || ''}
          </p>
        </div>
      </div>
      <div className="nft_deatils_main_btns w-full lg:w-auto mt-10 sm:mt-0">
        <button
          className={`${'nft_deatils_btns_staking'} flex justify-center items-center mb-4 m-auto`}
          type="button"
          disabled={isLoading}
          onClick={async () => {
            dispatch(setAction(Actions.stake));
          }}
        >
          STAKE NOW
        </button>
        <button
          className="nft_deatils_btns_pool flex justify-center items-center m-auto"
          type="button"
          onClick={() =>
            getProjectDetailPath(poolInfo.chainId, poolInfo.projectId)
          }
        >
          VIEW POOL DETAILS
        </button>
      </div>
      {action === Actions.stake && <StakeModal />}
    </div>
  );
}
export default PoolInfo;
